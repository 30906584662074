/* roboto-300 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 300;
  font-display: swap;
  src: url('/fonts/roboto-v20-latin-300.eot'); /* IE9 Compat Modes */
  src: local('Roboto Light'), local('Roboto-Light'),
      url('/fonts/roboto-v20-latin-300.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
      url('/fonts/roboto-v20-latin-300.woff2') format('woff2'), /* Super Modern Browsers */
      url('/fonts/roboto-v20-latin-300.woff') format('woff'), /* Modern Browsers */
      url('/fonts/roboto-v20-latin-300.ttf') format('truetype'), /* Safari, Android, iOS */
      url('/fonts/roboto-v20-latin-300.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-regular - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url('/fonts/roboto-v20-latin-regular.eot'); /* IE9 Compat Modes */
  src: local('Roboto'), local('Roboto-Regular'),
      url('/fonts/roboto-v20-latin-regular.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
      url('/fonts/roboto-v20-latin-regular.woff2') format('woff2'), /* Super Modern Browsers */
      url('/fonts/roboto-v20-latin-regular.woff') format('woff'), /* Modern Browsers */
      url('/fonts/roboto-v20-latin-regular.ttf') format('truetype'), /* Safari, Android, iOS */
      url('/fonts/roboto-v20-latin-regular.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-500 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 500;
  font-display: swap;
  src: url('/fonts/roboto-v20-latin-500.eot'); /* IE9 Compat Modes */
  src: local('Roboto Medium'), local('Roboto-Medium'),
      url('/fonts/roboto-v20-latin-500.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
      url('/fonts/roboto-v20-latin-500.woff2') format('woff2'), /* Super Modern Browsers */
      url('/fonts/roboto-v20-latin-500.woff') format('woff'), /* Modern Browsers */
      url('/fonts/roboto-v20-latin-500.ttf') format('truetype'), /* Safari, Android, iOS */
      url('/fonts/roboto-v20-latin-500.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* roboto-700 - latin */
@font-face {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-display: swap;
  src: url('/fonts/roboto-v20-latin-700.eot'); /* IE9 Compat Modes */
  src: local('Roboto Bold'), local('Roboto-Bold'),
      url('/fonts/roboto-v20-latin-700.eot?#iefix') format('embedded-opentype'), /* IE6-IE8 */
      url('/fonts/roboto-v20-latin-700.woff2') format('woff2'), /* Super Modern Browsers */
      url('/fonts/roboto-v20-latin-700.woff') format('woff'), /* Modern Browsers */
      url('/fonts/roboto-v20-latin-700.ttf') format('truetype'), /* Safari, Android, iOS */
      url('/fonts/roboto-v20-latin-700.svg#Roboto') format('svg'); /* Legacy iOS */
}
/* material icons */
@font-face {
  font-family: "Material Icons";
  font-style: normal;
  font-weight: 400;
  font-display: swap;
  src: url("/fonts/MaterialIcons-Regular.eot");  /* For IE6-8 */
  src: local("Material Icons"), local("MaterialIcons-Regular"), 
      url("/fonts/MaterialIcons-Regular.woff2") format("woff2"), 
      url("/fonts/MaterialIcons-Regular.woff") format("woff"), 
      url("/fonts/MaterialIcons-Regular.ttf") format("truetype");
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: 'liga';
}
